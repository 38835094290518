import { Tab } from "@headlessui/react";
import classNames from "classnames";
import React from "react";

import "./SidebarTabs.scss";

type TabItem = {
	label: string | React.FC | JSX.Element;
	content: string | React.FC | JSX.Element;
};

type SidebarTabsProps = {
	items: TabItem[];
	active: number;
	onChange: (index: number) => void;
};

const SidebarTabs: React.FC<SidebarTabsProps> = (props) => {
	const { items, active, onChange } = props;

	return (
		<Tab.Group selectedIndex={active} onChange={onChange}>
			<Tab.List className='sidebar-tabs-list'>
				{items.map((item) => {
					return (
						<Tab as={React.Fragment}>
							{({ selected }) => {
								let btnCls = classNames("tab-item", { selected: selected });
								return <button className={btnCls}>{item.label}</button>;
							}}
						</Tab>
					);
				})}
			</Tab.List>
			<Tab.Panels className='sidebar-tabs-panels'>
				{items.map((item) => {
					return <Tab.Panel className='tab-panel'>{item.content}</Tab.Panel>;
				})}
			</Tab.Panels>
		</Tab.Group>
	);
};

export default SidebarTabs;
