import { useEffect } from "react";
import { useGoogleLogin, googleLogout } from "@react-oauth/google";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "react-query";
import { toast } from "react-toastify";
import { useRecoilState } from "recoil";
import { toastOptions } from "../config";
import {
	getUserProgress,
	loginUser,
	verifyUserToken,
	saveUsername as saveUsernameMain,
} from "../services/userApi";
import { UserLoadingState, UserLoggedInState, UserState, UserTokenIdState } from "../store";
import { UserType } from "../types/userTypes";
import useSiteOptions from "./useSiteOptions";

const useLogin = () => {
	const [loading, setLoading] = useRecoilState(UserLoadingState);
	const [isLoggedIn, setIsLoggedIn] = useRecoilState(UserLoggedInState);
	const [token, setToken] = useRecoilState(UserTokenIdState);
	const [user, setUser] = useRecoilState(UserState);
	const { siteOptions } = useSiteOptions();
	const { mutateAsync, isLoading } = useMutation(loginUser);
	const { mutateAsync: verifyToken } = useMutation(verifyUserToken);
	const { t } = useTranslation();
	const {
		data: userProgress,
		isLoading: userProgressLoading,
		error: userProgressError,
		refetch: userProgressRefetch,
	} = useQuery(`userProgress.${token}`, () => getUserProgress(token || ""), { enabled: false });

	useEffect(() => {
		if (token !== null && !isLoggedIn) {
			handleVerifyToken(token);
		}
	}, [token, isLoggedIn, loading]);

	const onSuccessLogin = async (res: any) => {
		// Login to server
		try {
			const login = await mutateAsync(res.code);
			// Call Success Method
			if (login.status === "success") {
				postLoginSuccess(login.token, login.user, login.isNew);
			}
			if (login.status === "error") {
				postLoginError(login.message);
			}
		} catch (error) {
			showError("Server Error");
		}
		setLoading(false);
	};

	const onErrorLogin = (res: any) => {
		setLoading(false);
		console.log(res);
	};

	const postLoginSuccess = (token: string, user: UserType, isNew: boolean) => {
		setIsLoggedIn(true);
		setToken(token);
		setUser(user);
		setLoading(false);
		ShowWelcome(isNew, user);
	};

	const postLoginError = (message: string) => {
		setLoading(false);
		showError(message);
	};

	const onSuccessLogout = () => {
		setToken(null);
		setIsLoggedIn(false);
		setUser(null);
	};

	const handleVerifyToken = async (token: string) => {
		try {
			const res = await verifyToken(token);
			if (res.status === "success") {
				setToken(res.token);
				setUser(res.user);
				setIsLoggedIn(true);
			} else {
				setToken(null);
			}
		} catch (e) {
			setToken(null);
		}
		setLoading(false);
	};

	const ShowWelcome = (isNew: boolean, user: UserType) => {
		toast.success(
			isNew ? `Welcome ${user?.firstName}` : `Welcome back ${user?.firstName}`,
			toastOptions
		);
	};

	const showError = (message: string) => {
		toast.error(message, toastOptions);
	};

	const saveUsername = async (username: string) => {
		if (token) {
			const saved = await saveUsernameMain(token, username);
			if (saved.status === "success") {
				toast.success(t("usernameSaved"), toastOptions);
			} else {
				if (saved.error_code === "username_not_unique") {
					toast.error(t("usernameNotUnique"), toastOptions);
				} else {
					toast.error(t("usernameSaveError"), toastOptions);
				}
			}
		}
	};

	const login = useGoogleLogin({
		onSuccess: onSuccessLogin,
		flow: "auth-code",
		onError: onErrorLogin,
	});

	const logout = () => {
		googleLogout();
		onSuccessLogout();
	};

	return {
		token,
		user,
		saveUsername,
		isLoggedIn,
		login,
		logout: logout,
		loading: loading || isLoading,
		userProgressData: {
			data: userProgress,
			loading: userProgressLoading,
			error: userProgressError,
			refetch: userProgressRefetch,
		},
	};
};

export default useLogin;
