import classNames from "classnames";
import { Tab } from "@headlessui/react";
import "./TopNavTabs.scss";
import React from "react";

type TopNavTabsProps = {
	items: TabItemType[];
	active: number;
	onChange: (e: number) => void;
};

type TabItemType = {
	label: string | React.FC | JSX.Element;
	content: string | React.FC | JSX.Element;
};

const TopNavTabs: React.FC<TopNavTabsProps> = (props) => {
	const { items, active, onChange } = props;

	return (
		<Tab.Group selectedIndex={active} onChange={onChange}>
			<Tab.List className="topnav-tabs">
				{items.map((item) => {
					return (
						<Tab as={React.Fragment}>
							{({ selected }) => {
								let btnClass = classNames("topnav-tab", { active: selected });
								return <div className={btnClass}>{item.label}</div>;
							}}
						</Tab>
					);
				})}
			</Tab.List>
			<Tab.Panels className="px-2 py-3 overflow-y-scroll max-h-48">
				{items.map((item) => {
					return <Tab.Panel>{item.content}</Tab.Panel>;
				})}
			</Tab.Panels>
		</Tab.Group>
	);
};

export default TopNavTabs;
