import { Slide, ToastOptions } from 'react-toastify';

export const sidebarSizes = {
	left: 400,
	right: 300,
};

const getDevUrl = () => {
	console.log(window.location);
	let location = window.location;

	if (location.hostname.includes('localhost')) {
		return 'http://localhost:1111/map/admin';
	}

	return `${location.protocol}//${location.hostname}:1111/map/admin`;
};

export const base_url = 'admin';
// export const dev_base_url = "https://interactivemap.app/test1/admin";
export const dev_base_url = getDevUrl();

export const toastOptions: ToastOptions = {
	position: 'bottom-center',
	theme: 'light',
	autoClose: 1000,
	hideProgressBar: true,
	transition: Slide,
};
