import { combineReducers, configureStore } from "@reduxjs/toolkit";
import visitedMarkersSlice from "./slices/map/visitedMarkersSlice";

const rootReducer = combineReducers({
	visited: visitedMarkersSlice,
});

const store = configureStore({
	reducer: rootReducer,
});

export type RootState = ReturnType<typeof store.getState>;

export default store;
