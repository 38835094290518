import axios from "axios";
import { base_url, dev_base_url } from "../config";
import { InnerTooltipType, TimezoneItem } from "../types";
import { GroupedMap, MapDataType, SearchResultItemProps } from "../types/MapTypes";
import { UpdatesModalType } from "../types/ModalTypes";
import {
	InfoModalOptionsType,
	RightSidebarOptionsType,
	SiteOptionsType,
	TranslationsListType,
} from "../types/SiteOptionsType";

export let computed_url = process.env.NODE_ENV === "development" ? dev_base_url : base_url;

export const getMaps = () =>
	new Promise<GroupedMap[]>((resolve, reject) => {
		axios
			.get<any[]>(`${computed_url}/api/getmaps`)
			.then((res) => resolve(res.data))
			.catch((e) => reject(e));
	});

export const getAllMapsData = () =>
	new Promise<MapDataType[]>((resolve, reject) => {
		axios
			.get<MapDataType[]>(`${computed_url}/api/options.json`)
			.then((res) => resolve(res.data))
			.catch((e) => reject(e));
	});

export const getMapData = (mapId: string) =>
	new Promise<MapDataType>((resolve, reject) => {
		axios
			.get<MapDataType>(`${computed_url}/api/${mapId}/options.json`)
			.then((res) => resolve(res.data))
			.catch((e) => reject(e));
	});

export const getSiteOptions = () =>
	new Promise<SiteOptionsType>((resolve, reject) => {
		axios
			.get<SiteOptionsType>(`${computed_url}/api/getsiteoptions`)
			.then((res) => resolve(res.data))
			.catch((e) => reject(e));
	});

export const getUpdates = () =>
	new Promise<UpdatesModalType>((resolve, reject) => {
		axios
			.get<UpdatesModalType>(`${computed_url}/api/getUpdates`)
			.then((res) => resolve(res.data))
			.catch((e) => reject(e));
	});

export const getRightSidebarOptions = () =>
	new Promise<RightSidebarOptionsType>((resolve, reject) => {
		axios
			.get<RightSidebarOptionsType>(`${computed_url}/api/getsidebaroptions`)
			.then((res) => resolve(res.data))
			.catch((e) => reject(e));
	});

export const getInfoModalOptions = () =>
	new Promise<InfoModalOptionsType>((resolve, reject) => {
		axios
			.get<InfoModalOptionsType>(`${computed_url}/api/getInfoModalSettings`)
			.then((res) => resolve(res.data))
			.catch((e) => reject(e));
	});

export const getTooltips = () =>
	new Promise<InnerTooltipType[]>((resolve, reject) => {
		axios
			.get<InnerTooltipType[]>(`${computed_url}/api/getTooltips`)
			.then((res) => resolve(res.data))
			.catch((e) => reject(e));
	});

export const searchMarkers = (query: string , lang:string) =>
	new Promise<SearchResultItemProps[]>((resolve, reject) => {
		axios
			.get<SearchResultItemProps[]>(`${computed_url}/api/searchMarkers?query=${query}&lang=${lang}`)
			.then((res) => resolve(res.data))
			.catch((e) => reject(e));
	});

export const getTranslations = () =>
	new Promise<TranslationsListType>((resolve, reject) => {
		axios
			.get<TranslationsListType>(`${computed_url}/api/getTranslations`)
			.then((res) => resolve(res.data))
			.catch((e) => reject(e));
	});

export const getTimezones = () =>
	new Promise<TimezoneItem[]>((resolve, reject) => {
		axios
			.get<TimezoneItem[]>(`${computed_url}/api/getTimezones`)
			.then((res) => resolve(res.data))
			.catch((e) => reject(e));
	});
