import { isArray } from "lodash";
import React from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { Dropdown, DropDownMini, Loader } from "../../components";
import { getTranslations } from "../../services/api";

const LanguageDropdown: React.FC<{ isMini?: boolean }> = ({ isMini = false }) => {
	const { data, isLoading, isError, isFetched } = useQuery("translations", getTranslations);

	const { i18n } = useTranslation();

	const handleLanguageChange = (language: string) => {
		i18n.changeLanguage(language);
	};

	if (isLoading) return <Loader />;
	if (isError) return <div className="text-red-500">Error.</div>;

	if (isFetched && data && isArray(data)) {
		return (
			<>
				{!isMini ? (
					<Dropdown
						items={data.map((row) => ({
							name: row.name,
							value: row.handle,
							image: `http://purecatamphetamine.github.io/country-flag-icons/3x2/${row.country_code}.svg`,
						}))}
						onChange={(item) => handleLanguageChange(item.value)}
					/>
				) : (
					<DropDownMini
						items={data.map((row) => ({
							value: row.handle,
							image: `http://purecatamphetamine.github.io/country-flag-icons/3x2/${row.country_code}.svg`,
						}))}
						onChange={(item) => handleLanguageChange(item.value)}
					/>
				)}
			</>
		);
	}
	return null;
};

export default LanguageDropdown;
