import React, { useState } from "react";
import { FiMapPin, FiMenu, FiMessageCircle, FiUser, FiX } from "react-icons/fi";
import { useRecoilState } from "recoil";
import { TopNav, TopNavTabs } from "../../components";
import { TopNavLogo } from "../../components/TopNav/TopNav";
import { MainSidebarTabIndex } from "../../store";
import CommentsList from "../Comments/CommentsList";
import Profile from "../Profile/Profile";
import Clock from "./Clock";
import CustomButtons from "./CustomButtons";
import LanguageDropdown from "./LanguageDropdown";
import Maps from "./Maps";
import MarkerGroups from "./MarkerGroups";
import SearchBox from "./SearchBox";
import ShowHideButton from "./ShowHideButton";

const MobileNav: React.FC = () => {
	const [activeTab, setActiveTab] = useRecoilState(MainSidebarTabIndex);
	const [lowerOpen, setLowerOpen] = useState(false);

	return (
		<>
			<TopNav>
				<div className="upper">
					<TopNavLogo />
					<div className="flex-1" />
					<div className="flex items-center space-x-2">
						<LanguageDropdown isMini />

						<div
							className="bg-black/20 rounded w-8 h-8 flex items-center justify-center text-white hover:bg-black/30 shadow-md"
							onClick={() => setLowerOpen(!lowerOpen)}
						>
							{lowerOpen ? <FiX /> : <FiMenu />}
						</div>
					</div>
				</div>
				{lowerOpen && (
					<div className="lower">
						<div className="flex px-3 py-2">
							<Clock isMini />
							<div className="flex-1"></div>
						</div>
						<TopNavTabs
							items={[
								{
									label: <FiMapPin />,
									content: (
										<>
											<CustomButtons />
											<Maps className="border-y border-theme-600 mt-2" />
											<ShowHideButton />
											<SearchBox />
											<MarkerGroups />
										</>
									),
								},
								{
									label: <FiMessageCircle />,
									content: <CommentsList />,
								},
								{ label: <FiUser />, content: <Profile /> },
							]}
							active={activeTab}
							onChange={(newTab: number) => setActiveTab(newTab)}
						/>
					</div>
				)}
			</TopNav>
		</>
	);
};

export default MobileNav;
