import React, { useState } from "react";
import { useMaps } from "../../hooks";
import _ from "lodash";
import { useQuery } from "react-query";
import { searchMarkers } from "../../services/api";
import { useDebounce } from "use-debounce";
import { Loader } from "../../components";
import { useTranslation } from "react-i18next";

const SearchItem: React.FC<{ item: any }> = ({ item }) => {
	const { setActiveMarker, setCurrentMap } = useMaps();
	const { t } = useTranslation();

	const handleClick = async () => {
		setCurrentMap(item.map_id);
		_.delay(() => setActiveMarker(item.id), 500);
	};

	return (
		<div
			onClick={handleClick}
			className="flex flex-col text-white px-3 py-2 transition-colors cursor-pointer bg-black/10 rounded-sm hover:bg-black/20"
		>
			<div className="flex space-x-2">
				<img className="h-6 w-6" src={item.icon_image || item.cat_image} alt="markerimage" />
				<div>{t(`category.${item.cat_id}`, { defaultValue: item.name })}</div>
			</div>
			{item.note_title.length > 0 && (
				<div
					className="max-h-[500px] overflow-y-auto scroll-bar my-1"
					dangerouslySetInnerHTML={{ __html: item.note_title }}
				></div>
			)}
		</div>
	);
};

const SearchBox: React.FC = () => {
	const [query, setQuery] = useState("");
	const [debounceFilter] = useDebounce(query, 500);
	const {
		t,
		i18n: { language },
	} = useTranslation();

	const results = useQuery(
		["searchResults", [debounceFilter, language]],
		() => searchMarkers(debounceFilter, language),
		{
			enabled: Boolean(debounceFilter),
		}
	);

	return (
		<>
			<div className="flex mt-3">
				<input
					onChange={(e) => setQuery(e.target.value)}
					value={query}
					className="flex-1 bg-transparent border px-3 py-1.5 text-white border-lime-600 rounded-l-sm outline-none focus:ring-0"
				/>
				<button className="px-2.5 py-1 border border-lime-600 border-l-0 text-white hover:bg-lime-500/10 rounded-r-sm">
					{t("sidebar.search")}
				</button>
			</div>
			{results.isLoading && <Loader className="my-3" />}
			{results.isFetched && (
				<div className="space-y-2 mt-3 py-2 border-y border-black/20">
					<div className="text-md text-white">{t("sidebar.searchResults")}</div>
					{results.data?.map((item) => (
						<SearchItem item={item} />
					))}
				</div>
			)}
		</>
	);
};

export default SearchBox;
